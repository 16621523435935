<template>
  <v-container>
    <v-row>
      <v-col class="text-center feedback-title-top" cols="12">
        피드백
        <span v-if="isView === 1">완료</span>
        <span v-if="isView === 2">만료</span>
      </v-col>
      <v-col cols="12" v-if="isView === 0">
        <v-row justify="center">
          <v-col class="feedback-content text-center" cols="12">
            병원을 내원하셨습니까?
          </v-col>
          <v-col class="feedback-content text-center" cols="12">
            <v-switch
              hide-details
              color="reform_primary"
              inset
              :label="switchVal ? '네' : '아니요'"
              v-model="switchVal"
              style="margin: 0px auto; width: 200px"
            >
            </v-switch>
          </v-col>
          <v-col class="feedback-content text-center" cols="12">
            상세한 평가 부탁드립니다.
          </v-col>
          <v-col cols="12" style="margin-top: 20px">
            <v-row>
              <v-col
                cols="5"
                class="text-right feedback-rating feedback-rating-text"
              >
                시설
              </v-col>
              <v-col cols="7" class="text-left feedback-rating feedback-star">
                <star-rating
                  id="setstar"
                  :star-size="28"
                  v-model="fsilScore"
                  :border-width="5"
                  border-color="#d8d8d8"
                  :rounded-corners="true"
                  :star-points="[
                    23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34,
                    46, 19, 31, 17,
                  ]"
                ></star-rating>
              </v-col>
              <v-col
                cols="5"
                class="text-right feedback-rating feedback-rating-text"
              >
                의료 전문성
              </v-col>
              <v-col cols="7" class="text-left feedback-rating feedback-star">
                <star-rating
                  id="setstar"
                  :star-size="28"
                  v-model="proScore"
                  :border-width="5"
                  border-color="#d8d8d8"
                  :rounded-corners="true"
                  :star-points="[
                    23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34,
                    46, 19, 31, 17,
                  ]"
                ></star-rating>
              </v-col>
              <v-col
                cols="5"
                class="text-right feedback-rating feedback-rating-text"
              >
                친절도
              </v-col>
              <v-col cols="7" class="text-left feedback-rating feedback-star">
                <star-rating
                  id="setstar"
                  :star-size="28"
                  v-model="famiScore"
                  :border-width="5"
                  border-color="#d8d8d8"
                  :rounded-corners="true"
                  :star-points="[
                    23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34,
                    46, 19, 31, 17,
                  ]"
                ></star-rating>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="feedback-content text-center feedback-last" cols="12">
            추가 입력 사항
          </v-col>
          <v-col cols="9" class="text-center">
            <div class="textarea-wrap">
              <v-textarea
                v-model="addFeedbackText"
                @keyup.enter="focusControl()"
                outlined
                no-resize
              />
            </div>
          </v-col>
          <v-col cols="11" class="text-center">
            <v-btn
              class="feedback-btn"
              depressed
              dark
              rounded
              height="60px"
              width="150px"
              @click="visit()"
              :class="{
                feedbackBtnActive:
                  this.fsilScore > 0 && this.proScore > 0 && this.famiScore > 0,
              }"
            >
              확인
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="isView === 1">
        <v-row justify="center">
          <v-col class="feedback-total feedback-info" cols="10">
            <v-row>
              <v-col cols="12" class="feedback-total-text text-center">
                피드백 전송이 <b>완료</b>되었습니다.
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="isView === 2">
        <v-row justify="center">
          <v-col class="feedback-total feedback-info" cols="10">
            <v-row>
              <v-col cols="12" class="feedback-total-text text-center">
                피드백이 <b>만료</b>되었습니다.
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </v-container>
</template>

<script>
import DefaultPopup from "@/components/modal/DefaultPopup";
import Directive from "@/components/join/Directive";
import StarRating from "vue-star-rating";
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";

export default {
  components: {
    DefaultPopup,
    Directive,
    StarRating,
  },
  data() {
    return {
      popupSet: {},
      isView: 0,
      switchVal: false,
      fsilScore: 0,
      proScore: 0,
      famiScore: 0,
      addFeedbackText: "",
    };
  },
  created() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
    this.feedbackIsYn();
  },
  mounted() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
    ...mapGetters("util", ["GET_FEEDBACK_DATA"]),
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP"]),
    ...mapActions("request", ["VISIT_CHECK_UN_AUTH"]),
    ...mapActions("util", [
      "FEEDBACK_SUBMIT",
      "BRING_FEEDBACK_DATA",
      "FEEDBACK_IS_YN",
    ]),
    async visit() {
      if (this.checkFlag()) {
        try {
          const payload = {
            feedId: this.$route.params.code,
            visitYn: this.switchVal ? "Y" : "N",
            fsilScore: this.fsilScore,
            proScore: this.proScore,
            famiScore: this.famiScore,
            allScore: (this.fsilScore + this.proScore + this.famiScore) / 3,
            addConts: this.addFeedbackText,
          };

          //console.log("payload : ", payload);

          //유효성 검사
          //1. 피드백번호
          if (payload.feedId === null || payload.feedId === undefined) {
            // payload.referId = "";

            // var referIdBoo = /^[0-9]{8,14}$/;

            // if(!referIdBoo.test(payload.referId)){
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = "리스트를 불러올 수 없습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          await this.FEEDBACK_SUBMIT(payload).then(() => {
            this.isView = 1;
            /**
            setTimeout(() => {
              this.SET_POPUP(false);
              document.querySelector("a.hidden").click();
            }, 800);
             */
          });
        } catch (error) {
          console.log("error :", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.isView = 2;
            this.SET_POPUP(true);
            this.popupSet.title = "피드백 전송 실패";
            this.popupSet.content = "입력정보가 서버로 전송되지 못했습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.confirmBtnText = "확인";
          }
          /**
          setTimeout(() => {
            this.SET_POPUP(false);
            document.querySelector("a.hidden").click();
          }, 800);
           */
        }
      } else {
        this.SET_POPUP(true);
        this.popupSet.title = "별점 확인";
        this.popupSet.content =
          "시설, 의료 전문성, 친절도 별점을 모두 평가해주세요";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      }
    },
    checkFlag() {
      if (this.fsilScore < 1 || this.proScore < 1 || this.famiScore < 1) {
        return false;
      }
      return true;
    },
    winClose() {
      window.open("", "_self", "");
      window.close();
    },
    checkPhone() {
      this.form.phone.flag = false;
      this.form.phone.hasError = true;
      const phone = this.form.phone.value.replace(/\s/gi, "");
      if (phone === "") {
        this.form.phone.flag = false;
        this.form.phone.hasError = true;
        this.form.phone.errorTxt = "휴대폰 번호를 입력해주세요.";
        return false;
      }
      const isPhone = /^[0-9|-]{10,13}$/;
      if (!isPhone.test(phone)) {
        this.form.phone.errorTxt = "숫자만 입력해주세요.";
        this.form.phone.flag = false;
        this.form.phone.hasError = true;
        return false;
      }
      this.form.phone.flag = true;
      this.form.phone.hasError = false;
      this.form.phone.errorTxt = "";
      return true;
    },
    focusControl() {
      const focusControl = document.getElementById("focusControl");
      //console.log(focusControl);
      focusControl.focus({ preventScroll: true });
    },
    async feedbackIsYn() {
      try {
        await this.FEEDBACK_IS_YN(this.$route.params.code).then((data) => {
          //console.log(data);
          if (data === null || data === "") {
            this.SET_POPUP(true);
            this.isView = 2;
            this.popupSet.title = "요청실패";
            this.popupSet.content =
              "잘못된 접근방식입니다. 정확한 경로로 접근해주시기 바랍니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          } else if (data === "Y") {
            this.isView = 2;
          } else if (data === "N") {
            this.isView = 0;
          }
        });
      } catch (error) {
        console.log("error: ", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.isView = 2;
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "피드백 접근에 실패하였습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
  },
  destroyed() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "on",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
};
</script>

<style lang="scss" scoped>
.app-context .context.mobile {
  padding: 0 !important;
  .directive {
    padding: 35px 0;
  }
  .complete {
    position: relative;
    padding: 35px 0 0 0;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 4px;
      background: #146f83;
      top: 0;
    }
    p {
      font-family: "SUIT";
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      text-align: center;
      color: #000000;
      b {
        font-weight: bold;
        color: #146f83;
      }
    }
  }
}

.qr {
  width: 100%;
  padding: 30px 0 0 0;
  margin: 0;
  position: relative;
  &:after {
    content: "";
    background: #146f83;
    width: 100%;
    height: 3px;
    top: 0px;
    position: absolute;
  }
  h4.title {
    margin: 0 auto 12.5px auto;
    width: calc(100% - 20px);
    font-family: "SUIT";
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 26px;
    letter-spacing: normal;
    text-align: left;
    color: #146f83;
  }
  .notification {
    width: calc(100% - 20px);
    margin: 0 auto 30px auto;
    padding: 8px 14px;
    position: relative;
    border: 1px solid #707070;
    border-radius: 6px;
    p {
      font-family: "SUIT";
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 24px;
      letter-spacing: normal;
      text-align: left;
      color: #707070;
    }
  }
  .a-action {
    display: flex;
    padding: 0 0 20px 0;
    border: 0px solid #707070;
    border-width: 0px 0px 1px 0px;
    align-items: center;
    cursor: pointer;
    .check {
      margin: 0 8px 0 0;
      img {
      }
    }
    h4 {
      font-family: "SUIT";
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 36px;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
    }
  }
  .article {
    margin: 30px 0;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      margin: 17.5px 0 0 0;
      &:first-of-type {
        margin: 0;
      }
      .i-slot {
        display: flex;
        .check {
          margin: 0 12px 0 0;
          display: flex;
          img {
          }
        }
        h4 {
          font-family: "SUIT";
          font-size: 24px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 36px;
          letter-spacing: normal;
          text-align: left;
          color: #000000;
        }
      }
      .btn-wrap {
        margin: 0;
        height: 55px;
        width: 110px;
        background: transparent;
        button {
          background: #146f83;
          &.default {
            background: #474747;
          }
        }
      }
    }
  }
  .btn-wrap {
    margin: 45px auto 50px auto;
    background: #e9e9e9;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    &.active {
      background: #146f83;
    }
    button {
      background: transparent;
      border: 0;
      font-family: "SUIT";
      font-size: 21px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 36px;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
      width: 100%;
      height: inherit;
    }
  }
  .j-article {
    padding: 0 10px;
    &.first {
      margin: 0;
    }
    margin: 30px 0 0 0;
    h5.title {
      margin: 0 0 12.5px 0;
      font-family: "SUIT";
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 26px;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
    }
    .input-wrap {
      border: solid 1px #707070;
      position: relative;
      border-radius: 6px;
      input {
        border: 0;
        width: 100%;
        height: auto;
        background: transparent;
        font-family: "SUIT";
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 46px;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
        padding: 0 12px;
      }
    }
    p.warn-message {
      margin: 20px 0 0 0;
      font-family: "SUIT";
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 25px;
      letter-spacing: normal;
      text-align: left;
      color: red;
    }
  }
}
a.hidden {
  display: none;
}
.select-place {
  height: auto;
  position: relative;
  .select-wrap {
    width: 100%;
    margin: 0;
    position: relative;
    .v-select {
      height: 48px;
    }
    p.warn-message {
      margin: 10px 0 0 0;
    }
  }
}

/////////////리뉴얼/////////////////////////

.feedback-title-top {
  font-weight: 500;
  font-size: 34px;
  color: #146f83;
  margin: 50px 0px 50px 0px;
}

.feedback-info {
  padding: 35px 30px 35px 30px;
  color: #146f83;
  background-color: #f5f9fa;
  border-radius: 20px;
  margin-bottom: 50px;

  p {
    font-size: 16px;
    word-break: keep-all;
  }

  .space {
    margin: 40px 0px 40px 0px;
  }
}

.feedback-content {
  margin-top: 50px;
  font-size: 30px;
  font-weight: 500;

  span {
    font-size: 20px;
    word-break: keep-all;
  }
}

.feedback-last {
  margin-top: 30px;
}

.feedback-phone {
  margin-top: 19px;
  font-size: 20px;
  font-weight: 400;
  position: relative;
  top: -20px;
}

.feedback-btn {
  margin-top: 50px;
  margin-bottom: 100px;
  font-size: 25px;
}

.feedbackBtnActive {
  background-color: #146f83 !important;
}

.feedback-rating-text {
  height: 80px;
  font-size: 25px;
}

p.warn-message {
  margin: 0px 0px 0px 20px;
  font-family: "SUIT";
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 25px;
  letter-spacing: normal;
  text-align: left;
  color: red;
  position: relative;
  top: -30px;
}

.feedback-total {
  .feedback-total-text {
    font-size: 30px;
    font-weight: 500;
    color: black;
  }

  .feedback-sub-text {
    font-size: 24px;
    font-weight: 400;
  }
}
.feedback-rating {
  position: relative;
  left: 100px;
}

@media screen and (max-width: 1880px) {
  .feedback-rating {
    position: relative;
    left: 30px;
  }
}

@media screen and (max-width: 800px) {
  .feedback-rating {
    left: 0px;
  }
}

@media screen and (max-width: 700px) {
  .feedback-total-text {
    font-size: 24px !important;
  }
}

@media screen and (max-width: 530px) {
  .feedback-total-text {
    font-size: 22px !important;
  }
}

@media screen and (max-width: 440px) {
  .feedback-total-text {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 400px) {
  .feedback-content {
    font-size: 22px !important;
  }
  .feedback-rating-text {
    font-size: 20px !important;
  }
  .feedback-rating {
    position: relative;
    left: -30px;
  }
}
@media screen and (max-width: 380px) {
  .feedback-total-text {
    font-size: 16px !important;
  }
  .feedback-title-top {
    font-size: 30px;
  }
}

@media screen and (max-width: 340px) {
  .feedback-content {
    font-size: 20px !important;
  }
  .feedback-rating-text {
    font-size: 18px !important;
  }
  .feedback-rating {
    left: -30px;
  }
  .feedback-star {
    left: -45px;
  }
}
</style>

<style>
.v-input--switch__track {
  width: 120px !important;
}
.v-application--is-ltr
  .v-input--switch--inset.v-input--is-dirty
  .v-input--selection-controls__ripple,
.v-application--is-ltr
  .v-input--switch--inset.v-input--is-dirty
  .v-input--switch__thumb {
  transform: translate(90px, 0) !important;
}
.v-label {
  left: 80px !important;
  font-size: 23px;
  font-weight: 600 !important;
}
.vue-star-rating-rating-text {
  display: none;
}
input,
textarea {
  -moz-user-select: auto;

  -webkit-user-select: auto;

  -ms-user-select: auto;

  user-select: auto;
}
</style>
